import React, { useState } from "react";
import firebase from "firebase/app";
import { Typography, Button } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import earth_logo from "./earth_logo.png";

interface Props {
  survey: string;
  onStart: () => void;
}

const WelcomeScreen: React.FC<Props> = ({ survey, onStart }) => {
  const [loading, setLoading] = useState(false);

  async function handleStart() {
    setLoading(true);

    let resId = localStorage.getItem("response_id_v2");
    if (resId !== null) {
      const doc = await firebase
        .firestore()
        .collection("responses")
        .doc(resId)
        .get();

      if (!doc.exists) {
        resId = null;
      }
    }

    if (resId === null) {
      const doc = await firebase.firestore().collection("responses").add({
        survey_id: survey,
        page: 0,
        completed: false,
        created_at: firebase.firestore.Timestamp.now(),
      });

      resId = doc.id;
      localStorage.setItem("response_id_v2", resId);
    }

    onStart();
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={earth_logo} style={{ width: 70, height: 70 }} alt="logo" />
      <Typography variant="h4" style={{ margin: 16, textAlign: "center" }}>
        Survey: Foundations of Wellbeing
      </Typography>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://positivehealingtherapy.com"
      >
        <Typography style={{ margin: 16, textAlign: "center" }}>
          <span>positivehealingtherapy.com </span>
          <OpenInNewIcon
            fontSize="small"
            style={{ verticalAlign: "text-bottom" }}
          />
        </Typography>
      </a>
      <Typography
        variant="body1"
        style={{ margin: "8px 16px", textAlign: "center" }}
      >
        We're conducting this survey to help us improve your (and others') sense
        of wellbeing (physically, mentally, emotionally and spiritually).
        <br />
        Thank you for taking a few moments to answer our questions - we
        appreciate your participation.
      </Typography>
      <Typography
        variant="body2"
        style={{ margin: "8px 16px", textAlign: "center" }}
      >
        This survey and the answers you submit will be anonymous.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 16, width: "100%", maxWidth: 300 }}
        size="large"
        disabled={loading}
        onClick={handleStart}
      >
        Start
      </Button>
    </div>
  );
};

export default WelcomeScreen;
