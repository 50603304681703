import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DataProvider } from "./DataContext";

import firebase from "firebase/app";
import "firebase/firestore";

import "./index.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

firebase.initializeApp({
  apiKey: "AIzaSyDJmAx4sjPMyvbieb1nfY82RZesZQKxJ9w",
  authDomain: "pht-survey.firebaseapp.com",
  databaseURL: "https://pht-survey.firebaseio.com",
  projectId: "pht-survey",
  storageBucket: "pht-survey.appspot.com",
  messagingSenderId: "1049602765775",
  appId: "1:1049602765775:web:be549bcf8c5ff3e139d8ba",
});

const theme = createMuiTheme({
  palette: {
    secondary: blue,
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <DataProvider>
      <App />
    </DataProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
