import React, { useContext } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";

import { RadioQuestion } from "./data";
import { DataContext } from "./DataContext";

const useStyles = makeStyles((theme) => ({
  options: {
    margin: theme.spacing(1, 2),
  },
}));

interface Props {
  question: RadioQuestion;
}

const RadioQuestionView: React.FC<Props> = ({ question }) => {
  const classes = useStyles();
  const context = useContext(DataContext);
  return (
    <div className={classes.options}>
      <RadioGroup
        value={context.getValue(question.id) || null}
        onChange={(e) => context.setValue(question.id, e.target.value)}
      >
        {question.options.map(({ label }, i) => (
          <FormControlLabel
            key={i}
            value={label}
            label={label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RadioQuestionView;
