import React, { useContext, useState } from "react";
import firebase from "firebase/app";
import {
  Button,
  Card,
  CardContent,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import QuestionView from "./QuestionView";
import { DataContext } from "./DataContext";
import { Page } from "./data";

export interface Props {
  page: Page;
  onNext?: (data: any) => void;
}

const NavigationButton: React.FC<{
  disabled: boolean;
  onClick?: () => void;
}> = ({ children, disabled, onClick }) => {
  return (
    <Button
      size="large"
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      style={{
        width: 150,
      }}
    >
      {children}
    </Button>
  );
};

const QuestionPage: React.FC<Props> = ({ page, onNext }) => {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  async function handleNext() {
    setLoading(true);

    let data: Record<string, any> = {};
    for (const q of page.questions) {
      data[q.id] = q.getValue ? q.getValue(context) : context.getValue(q.id);
    }

    const valid = page.questions.every((q) =>
      q.validate ? q.validate(data[q.id], context) : !!data[q.id]
    );

    if (!valid) {
      setInvalid(true);
      setLoading(false);
      return;
    }

    const id = localStorage.getItem("response_id_v2");
    const doc = firebase.firestore().collection("responses").doc(id!!);
    const pageNum = (await doc.get()).get("page");
    await doc.set({ data, page: pageNum + 1 }, { merge: true });

    onNext && onNext(data);
  }

  return (
    <Card>
      <CardContent style={{ marginTop: -16 }}>
        {invalid && (
          <div style={{ marginTop: 32, color: "red" }}>
            <Snackbar
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              open={invalid}
              autoHideDuration={3000}
              onClose={() => setInvalid(false)}
            >
              <Alert variant="filled" severity="error">
                You must answer all of the questions marked with a (*) to
                proceed.
              </Alert>
            </Snackbar>
          </div>
        )}
        {page.questions
          .filter((q) => (q.isVisible ? q.isVisible(context) : true))
          .map((q, i) => (
            <div key={i} style={{ marginTop: 32, marginBottom: 32 }}>
              <QuestionView question={q} />
            </div>
          ))}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <NavigationButton disabled={loading} onClick={handleNext}>
            Next
          </NavigationButton>
        </div>
        <Typography style={{ marginTop: 16 }}>
          Questions marked with a <span style={{ color: "red" }}>*</span> are
          required.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default QuestionPage;
