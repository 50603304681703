import React, { useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { CheckboxQuestion } from "./data";
import { DataContext } from "./DataContext";

const useStyles = makeStyles((theme) => ({
  options: {
    margin: theme.spacing(1, 2),
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
  },
  other: {
    margin: theme.spacing(1, 2),
  },
}));

interface Props {
  question: CheckboxQuestion;
}

const CheckboxQuestionView: React.FC<Props> = ({ question }) => {
  const classes = useStyles();
  const context = useContext(DataContext);
  const value = context.getValue(question.id) || {};

  return (
    <>
      <div className={classes.options}>
        {question.options.map(({ label }, i) => (
          <FormControlLabel
            key={i}
            label={label}
            control={
              <Checkbox
                checked={value[label] || false}
                onChange={(e) =>
                  context.setValue(question.id, {
                    ...value,
                    [label]: e.target.checked,
                  })
                }
              />
            }
          />
        ))}
      </div>
      {question.other && (
        <div className={classes.other}>
          <TextField
            placeholder={question.other.label}
            fullWidth
            value={value.other || ""}
            onChange={(e) =>
              context.setValue(question.id, { ...value, other: e.target.value })
            }
          />
        </div>
      )}
    </>
  );
};

export default CheckboxQuestionView;
