import React, { useContext } from "react";
import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import { TableQuestion } from "./data";
import { DataContext } from "./DataContext";

interface Props {
  question: TableQuestion;
}

const TableQuestionView: React.FC<Props> = ({ question }) => {
  const context = useContext(DataContext);
  const value = context.getValue(question.id) || {};
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <br />
      {question.rows.map((r) => (
        <div
          key={r}
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <div
            style={{
              width: 120,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Typography>{r}</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <FormControl style={{ width: "100%", maxWidth: 300 }}>
              <Select
                value={value[r] || ""}
                displayEmpty
                onChange={(e) =>
                  context.setValue(question.id, {
                    ...value,
                    [r]: e.target.value,
                  })
                }
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {question.cols.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableQuestionView;
