import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { Typography, TextField, Button } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const SuccessScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [comments, setComments] = useState<string | null>(null);

  async function completeSurvey() {
    const id = localStorage.getItem("response_id_v2");
    const doc = firebase.firestore().collection("responses").doc(id!!);
    await doc.update({
      completed: true,
      completed_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  async function submitComments() {
    setLoading(true);

    const data: Record<string, string> = {};

    if (name !== null && name.trim().length > 0) {
      data.name = name.trim();
      setName(null);
    }

    if (email !== null && email.trim().length > 0) {
      data.email = email.trim();
      setEmail(null);
    }

    if (comments !== null && comments.trim().length > 0) {
      data.comments = comments.trim();
      setComments(null);
    }

    if (Object.keys(data).length === 0) {
      setLoading(false);
      return;
    }

    const id = localStorage.getItem("response_id_v2");
    const doc = firebase.firestore().collection("responses").doc(id!!);
    await doc.set(data, { merge: true });

    setSubmitted(true);
    setLoading(false);
  }

  useEffect(() => {
    completeSurvey();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" style={{ margin: 16, textAlign: "center" }}>
        Thank you for completing this survey!
      </Typography>
      <Typography variant="h5" style={{ margin: 16, textAlign: "center" }}>
        <CheckCircleOutlineIcon
          fontSize="large"
          style={{ color: "green", verticalAlign: "middle" }}
        />
        <span> Your responses have been recorded.</span>
      </Typography>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://positivehealingtherapy.com"
      >
        <Typography style={{ margin: 16, textAlign: "center" }}>
          <span>positivehealingtherapy.com </span>
          <OpenInNewIcon
            fontSize="small"
            style={{ verticalAlign: "text-bottom" }}
          />
        </Typography>
      </a>
      <div
        style={{
          width: "100%",
          maxWidth: 400,
          marginLeft: 16,
          marginRight: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" style={{ textAlign: "center" }}>
          We will shortly be running a program that will reduce stress &
          anxiety, and increase emotional resilience, self-esteem & general
          well-being. If you're interested in gaining these benefits, and would
          like more information, please enter your name and email below.
        </Typography>
        <TextField
          placeholder="Name"
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          placeholder="Email address"
          variant="outlined"
          fullWidth
          style={{ marginTop: 16 }}
          value={email || ""}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          placeholder="Any other comments"
          variant="outlined"
          fullWidth
          multiline
          rows={8}
          style={{ marginTop: 16 }}
          value={comments || ""}
          onChange={(e) => setComments(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          style={{ marginTop: 16 }}
          size="large"
          onClick={submitComments}
        >
          Submit
        </Button>
        {submitted && (
          <Typography style={{ marginTop: 16 }}>
            Thanks, we'll be in touch!
          </Typography>
        )}
      </div>
    </div>
  );
};

export default SuccessScreen;
