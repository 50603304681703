import React from "react";
import { makeStyles } from "@material-ui/core";

import QuestionView from "./QuestionView";
import { SubQuestionsQuestion } from "./data";

const useStyles = makeStyles((theme) => ({
  questions: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
  },
  question: {
    margin: theme.spacing(4, 0),
  },
}));

const SubQuestionsQuestionView: React.FC<{
  question: SubQuestionsQuestion;
}> = ({ question }) => {
  const classes = useStyles();
  return (
    <div className={classes.questions}>
      {question.questions.map((q) => (
        <div key={q.id} className={classes.question}>
          <QuestionView question={q} nested />
        </div>
      ))}
    </div>
  );
};

export default SubQuestionsQuestionView;
