import React from "react";
import { Question } from "./data";

import ShortAnswerQuestionView from "./ShortAnswerQuestionView";
import SubQuestionsQuestionView from "./SubQuestionsQuestionView";
import ScaleQuestionView from "./ScaleQuestionView";
import RadioQuestionView from "./RadioQuestionView";
import CheckboxQuestionView from "./CheckboxQuestionView";
import LongAnswerQuestionView from "./LongAnswerView";
import { Typography } from "@material-ui/core";
import TableQuestionView from "./TableQuestionView";

interface Props {
  question: Question;
  nested?: boolean;
}

function viewForQuestion(question: Question) {
  switch (question.type) {
    case "short":
      return <ShortAnswerQuestionView question={question} />;

    case "long":
      return <LongAnswerQuestionView question={question} />;

    case "sub":
      return <SubQuestionsQuestionView question={question} />;

    case "scale":
      return <ScaleQuestionView question={question} />;

    case "radio":
      return <RadioQuestionView question={question} />;

    case "checkbox":
      return <CheckboxQuestionView question={question} />;

    case "table":
      return <TableQuestionView question={question} />;
  }
}

const QuestionView: React.FC<Props> = ({ question, nested }) => {
  const view = viewForQuestion(question);
  return (
    <div>
      <Typography variant={!nested ? "h6" : undefined}>
        {question.text}
        {!question.optional && <span style={{ color: "red" }}> *</span>}
      </Typography>
      <div>{view}</div>
    </div>
  );
};

export default QuestionView;
