import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import firebase from "firebase/app";
import {
  AppBar,
  Container,
  LinearProgress,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";

import "./App.css";
import QuestionPage from "./QuestionPage";
import SuccessScreen from "./SuccessScreen";

import { Page } from "./data";
import { PAGES } from "./questions";
import WelcomeScreen from "./WelcomeScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    paddingTop: theme.spacing(2),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
  pageContainer: {
    position: "relative",
  },
  page: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  pageBottomSpacer: {
    width: "100%",
    height: theme.spacing(2),
  },
}));

const PageFrame: React.FC<{ key: any; in: boolean }> = ({
  in: in_,
  children,
}) => {
  const classes = useStyles();
  return (
    <CSSTransition in={in_} timeout={300} classNames="page" unmountOnExit>
      <div className={classes.page}>
        {children}
        <div className={classes.pageBottomSpacer} />
      </div>
    </CSSTransition>
  );
};

function getQuestionCount(pages: Page[]) {
  return pages.reduce((acc, p) => acc + p.questions.length, 0);
}

function getProgress(pages: Page[], page: number) {
  if (page <= 0) return 0;
  if (page >= pages.length) return 100;
  let progress = 0;
  for (let i = 0; i < page; i++) {
    progress += pages[i].questions.length;
  }
  return (progress / getQuestionCount(pages)) * 100;
}

const App: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [survey, setSurvey] = useState<string | null>(null);
  const [page, setPage] = useState(-1);

  async function load() {
    const pathParts = window.location.pathname.split("/");
    if (pathParts.length < 2) {
      setLoading(false);
      return;
    }

    const surveyId = pathParts[1];
    if (surveyId.trim().length === 0) {
      setLoading(false);
      return;
    }

    const survey = await firebase
      .firestore()
      .collection("surveys")
      .doc(surveyId.trim())
      .get();

    if (!survey.exists) {
      setLoading(false);
      return;
    }

    setSurvey(surveyId);

    const id = localStorage.getItem("response_id_v2");
    if (id !== null) {
      const doc = await firebase
        .firestore()
        .collection("responses")
        .doc(id)
        .get();

      if (doc.exists) {
        setPage(doc.get("page"));
      } else {
        localStorage.removeItem("response_id_v2");
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    load();
  }, []);

  function onStart() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPage((q) => Math.min(q + 1, PAGES.length));
  }

  function onNextPage() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPage((q) => Math.min(q + 1, PAGES.length));
  }

  if (loading) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">Foundations of Wellbeing</Typography>
          </Toolbar>
        </AppBar>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={getProgress(PAGES, page)}
          className={classes.progress}
        />
      </div>
      <div className={classes.contentRoot}>
        <Container className={classes.content}>
          {survey !== null ? (
            <div className={classes.pageContainer}>
              <PageFrame key={-1} in={page === -1}>
                <WelcomeScreen survey={survey} onStart={onStart} />
              </PageFrame>
              {PAGES.map((p, i) => (
                <PageFrame key={i} in={page === i}>
                  <QuestionPage page={p} onNext={onNextPage} />
                </PageFrame>
              ))}
              <PageFrame key={PAGES.length} in={page === PAGES.length}>
                <SuccessScreen />
              </PageFrame>
            </div>
          ) : (
            <Typography variant="h3" style={{ textAlign: "center" }}>
              Not Found
            </Typography>
          )}
        </Container>
      </div>
    </div>
  );
};

export default App;
