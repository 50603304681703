import React, { useContext } from "react";
import { TextField } from "@material-ui/core";
import { ShortAnswerQuestion } from "./data";
import { DataContext } from "./DataContext";

interface Props {
  question: ShortAnswerQuestion;
}

const ShortAnswerQuestionView: React.FC<Props> = ({ question }) => {
  const context = useContext(DataContext);
  return (
    <TextField
      placeholder="Your answer"
      fullWidth
      value={context.getValue(question.id) || ""}
      onChange={(e) => context.setValue(question.id, e.target.value)}
    />
  );
};

export default ShortAnswerQuestionView;
