import React, { createContext, useState } from "react";

export interface IDataContext {
  getValue: (key: string) => any;
  setValue: (key: string, value: any) => void;
}

export const DataContext = createContext<IDataContext>({
  getValue: () => {},
  setValue: () => {},
});

export const DataProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<Record<string, any>>({});

  function getValue(key: string) {
    return state[key];
  }

  function setValue(key: string, value: any) {
    setState((s) => ({ ...s, [key]: value }));
  }

  return (
    <DataContext.Provider value={{ getValue, setValue }}>
      {children}
    </DataContext.Provider>
  );
};
