import React from "react";
import { Page } from "./data";

export const PAGES: Page[] = [
  {
    questions: [
      {
        id: "age",
        text: "What is your age range?",
        type: "radio",
        options: [
          { label: "18-25" },
          { label: "26-40" },
          { label: "41-60" },
          { label: "61+" },
        ],
      },
    ],
  },
  {
    questions: [
      {
        id: "1",
        text:
          "1. How important do you think it is to maintain a sense of wellbeing (feeling contented, healthy and happy), on a scale of 1-10?",
        type: "scale",
        optional: true,
        min: {
          value: 1,
          label: "Not important",
        },
        max: {
          value: 10,
          label: "Very important",
        },
      },
      {
        id: "2",
        text: (
          <>
            2. What percentage of time in the last 2 weeks have <u>you</u> been
            able to maintain a sense of wellbeing?
          </>
        ),
        type: "radio",
        options: [
          { label: "0% - 20%" },
          { label: "21% - 40%" },
          { label: "41% - 60%" },
          { label: "61% - 80%" },
          { label: "81% - 100%" },
        ],
      },
    ],
  },
  {
    questions: [
      {
        id: "3",
        text:
          "3. Below is a list of feelings. Please select the option which describes how often you have felt each one in the last month.",
        type: "table",
        rows: [
          "Anxious",
          "Afraid",
          "Lonely",
          "Sad",
          "Worried",
          "Stressed",
          "Angry",
          "Peaceful",
        ],
        cols: ["Never", "Rarely", "Sometimes", "Often", "Always"],
        optional: true,
        getValue: (context) => context.getValue("3") || {},
      },
    ],
  },
  {
    questions: [
      {
        id: "4",
        text:
          "4. Would you like a programme to help you learn practices to increase your sense of wellbeing?",
        type: "radio",
        options: [{ label: "Yes" }, { label: "No" }, { label: "Maybe" }],
      },
      {
        id: "5",
        text:
          "5. How many one hour sessions every week would you be willing to invest in learning the wellbeing practices?",
        type: "radio",
        options: [
          { label: "1 session" },
          { label: "2 sessions" },
          { label: "3 sessions" },
        ],
        isVisible: (context) =>
          !!context.getValue("4") && context.getValue("4") !== "No",
        validate: (value, context) => context.getValue("4") === "No" || !!value,
      },
      {
        id: "6",
        text: "6. Would you prefer the wellbeing program to be:",
        type: "radio",
        options: [
          { label: "online" },
          { label: "face to face" },
          { label: "don't mind" },
        ],
        isVisible: (context) =>
          !!context.getValue("4") && context.getValue("4") !== "No",
        validate: (value, context) => context.getValue("4") === "No" || !!value,
      },
    ],
  },
  {
    questions: [
      {
        id: "7",
        text: "7. Are you currently working?",
        type: "radio",
        options: [{ label: "Yes" }, { label: "No" }],
      },
      {
        id: "8",
        text: "8. What do you work as?",
        type: "short",
        isVisible: (context) => context.getValue("7") === "Yes",
        getValue: (context) => context.getValue("8") || null,
        validate: (value, context) =>
          context.getValue("7") !== "Yes" || !!value,
      },
      {
        id: "9",
        text:
          "9. How much extra stress (if any) do you feel as a result of your job?",
        type: "scale",
        min: {
          value: 1,
          label: "None",
        },
        max: {
          value: 10,
          label: "Overwhelming",
        },
        isVisible: (context) => context.getValue("7") === "Yes",
        getValue: (context) => context.getValue("9") || null,
        validate: (value, context) =>
          context.getValue("7") !== "Yes" || !!value,
      },
      {
        id: "10",
        text:
          "10. Do you think managing stress at work would increase your sense of wellbeing?",
        type: "radio",
        options: [{ label: "Yes" }, { label: "No" }, { label: "Maybe" }],
        isVisible: (context) => context.getValue("7") === "Yes",
        getValue: (context) => context.getValue("9") || null,
        validate: (value, context) =>
          context.getValue("7") !== "Yes" || !!value,
      },
    ],
  },
];
