import React, { useContext } from "react";
import { TextField, makeStyles } from "@material-ui/core";

import { LongAnswerQuestion } from "./data";
import { DataContext } from "./DataContext";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    margin: theme.spacing(2, 1),
  },
  input: {
    width: "100%",
    maxWidth: 600,
  },
}));

interface Props {
  question: LongAnswerQuestion;
}

const LongAnswerQuestionView: React.FC<Props> = ({ question }) => {
  const classes = useStyles();
  const context = useContext(DataContext);
  return (
    <div className={classes.inputContainer}>
      <TextField
        className={classes.input}
        placeholder="Your answer"
        variant="filled"
        multiline
        rows={8}
        value={context.getValue(question.id) || ""}
        onChange={(e) => context.setValue(question.id, e.target.value)}
      />
    </div>
  );
};

export default LongAnswerQuestionView;
