import React, { useContext } from "react";
import { Slider, Typography, makeStyles } from "@material-ui/core";

import { ScaleQuestion } from "./data";
import { DataContext } from "./DataContext";

const useStyles = makeStyles((theme) => ({
  scale: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface Props {
  question: ScaleQuestion;
}

const ScaleQuestionView: React.FC<Props> = ({ question }) => {
  const classes = useStyles();
  const context = useContext(DataContext);
  const marks = [];

  for (let i = question.min.value; i <= question.max.value; i++) {
    marks.push({ value: i, label: i });
  }

  return (
    <>
      <div className={classes.scale}>
        <Slider
          value={context.getValue(question.id) || question.min.value}
          marks={marks}
          min={question.min.value}
          max={question.max.value}
          step={1}
          onChange={(_, v) => context.setValue(question.id, v)}
        />
      </div>
      <div className={classes.labels}>
        <Typography>{question.min.label}</Typography>
        <Typography>{question.max.label}</Typography>
      </div>
    </>
  );
};

export default ScaleQuestionView;
